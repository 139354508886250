<template>
  <Popup
    :width="width"
    :margin="margin"
    :radiusType="radiusType"
    :closePopupEvent="closePopup"
  >
    <div class="content-share " slot="component">
      <div>
        <p class="content-share-link-title">{{ $t("share this link") }}</p>
        <p class="Clipboard" v-if="copiedText"> {{$t("copied")}} </p>
        <!-- <p class="content-share-">
          {{ $t("Watch") }} "{{ content.title }}" {{ $t("on Jhakaas") }}!
        </p> -->
        <div class="content-share-input" dir="ltr">
          <input
            class="content-share-input-link-field"
            id="content-share-link"
            type="text"
            @keydown.prevent
            v-model="link"
            ref="link"
          />
          <button
            @click="copyText"
            class="content-share-input-btn-copy copy-btn primary-nammaflix-button"
            :data-clipboard-text="link"
          >
            {{ $t("copy") }}
          </button>
        </div> 
      </div>
      <!-- <p class="content-share-title">Share to</p> -->
      <div>
        <ul class="icon_list">
              <li tabindex="1" class="icon"  @mouseover="actSoicalLogin('fb')">
                <a 
                :href="'https://www.facebook.com/share.php?u=' + link" 
                  rel="noopener"
                  aria-label="facebook link"
                  target="_blank"
                >
                  <img
                    src="@/assets/icons/facebookicon.svg"
                    alt="facebook_img"
                  />
                </a>
              </li>
              <li 
              tabindex="1"
              class="icon"  @mouseover="actSoicalLogin('twitter')"
>
                <a 
                 target="_blank"
                  :href="'https://twitter.com/intent/tweet?url=' + link"  
                  rel="noopener"
                  aria-label="twitter link"
                  
                >
                  <img src="@/assets/icons/twittericon.svg" alt="twitter_img" />
                </a>
              </li>
        </ul>
      </div>
    </div>
  </Popup>
</template>

<script>
import "../../../node_modules/node-snackbar/dist/snackbar.css";
import googleAnalytics from "@/mixins/googleAnalytics.js";
import cleverTap from "@/mixins/clevertap.js";
import common from "@/mixins/common.js";
import { mapGetters } from "vuex";
import { _providerId, _projectName } from "@/provider-config.js";
import { eventBus } from "@/eventBus";

import { showSnackBar } from "@/utilities";
import content from "../../store/Modules/Content/content";

export default {
  props: {
    closePopup: {
      type: Function,
    },
    content: {
      type: Object,
    },
  },
  data() {
    return {
      width: "40%",
      margin: "10% auto",
      radiusType: "full",
      link: null,
      clipboard: undefined,
      localDisplayLang: "",
      copiedText: false,
      // mediaQuery: {
      //   small: window.matchMedia("(max-width: 600px)"),
      // }
    };
  },
  computed: {
    ...mapGetters(["availabilityList"]),
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });
    this.updatePropertiesByViewport();
    // Listen for viewport size changes
    for (const key in this.mediaQuery) {
      this.mediaQuery[key].addListener(this.updatePropertiesByViewport);
    }
    //assigning availability list response.
  },

  mounted() {
    setTimeout(() => {
      this.$refs.link.readOnly = true;

      let dom = document.getElementById("popup-close-icon");
      dom.style.opacity = 1;
    }, 100);
    // this.createLink("sharing");
    this.link = window.location.href;
  },
  methods: {

    updatePropertiesByViewport() {
      // Update properties based on viewport size
      if (this.mediaQuery.small.matches) {
        this.width = "100%";
        this.margin = "5% 0 0 10px";
      }
     
    },
    copyText() {
      this.copiedText = true;
      setTimeout(() => {
        this.copiedText = false;
      }, 2000);

      var copyText = document.getElementById("content-share-link");

      // Select the text field
      copyText.select();
      copyText.setSelectionRange(0, 99999); // For mobile devices

      // Copy the text inside the text field
      navigator.clipboard.writeText(copyText.value);
      this.$refs.link.focus();
    },
    actSoicalLogin (social) {
      let colorCode = '';
      if (social == 'fb') {
        colorCode = '#1877F2'
      } else if (social == 'insta') {
        colorCode = '#D748C0'
      } else if (social == 'twitter') {
        colorCode = '#1E90FF'
      } else if (social == 'tube') {
        colorCode = '#ED3833'
      } 
      document.documentElement.style.setProperty('--social-login-hover-color', colorCode);
    },
    createLink(medium) {
      console.log("what is the route in share content", this.$route);
      let self = this;

      var linkData = {
        // branch_key: "key_live_ndGW8YRJAehKig50HTghTcooDsdLBpj3",
        campaign: "content",
        channel: medium,
        stage: "new user",
        alias: "",
        data: {
          custom_bool: true,
          custom_int: Date.now(),
          custom_string: "Deep Link",
          $canonical_identifier: this.content.objectid,
          $og_title: this.content.title,
          $og_description: this.content.shortdescription,
          //$og_image_url: this.getPoster(this.content),
          $desktop_url: "https://noorplay.com",
          // $desktop_url: "https://netfive-web.web.app",
          // $desktop_url: "https://net5.in",
          $ios_deeplink_path: `net5://content/details/${this.content.objectid}`,
          $android_deeplink_path: `net5://content/details/${this.content.objectid}`,
          $desktop_deeplink_path: `${window.location.origin}/content/details/${this.content.objectid}`,
        },
      };

      branch.link(linkData, function(err, link) {
        let item = self.content;
        let mediaType = "";
        let title
        if (item.category == "TVSHOW") {
          if (self.localDisplayLang == "eng") {
            title = this.spacialCharEncoding(item.title);
          } else {
            title = "title";
          }
          mediaType = "/series/";
        } else {
          if (self.localDisplayLang == "eng") {
            title = this.spacialCharEncoding(item.title);
          } else {
            title = "title";
          }
          mediaType = "/movie/";
        }

        let urlData = mediaType + title + "/" + item.objectid;

        console.error("urlData", urlData);

        link = location.origin + `${urlData}`;
        self.link = link;

        // self.createLink();
        // self.initTwitterUrl();
      });
    },
  },
  destroyed() {
    // Remove event listeners when component is destroyed
    for (const key in this.mediaQuery) {
      this.mediaQuery[key].removeListener(this.updatePropertiesByViewport);
    }
  },
  components: {
    Popup: () =>
      import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue"),
  },
  mixins: [googleAnalytics, cleverTap, common],
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "./contentShare.scss"

</style>
